import CropperElement from '@cropper/element';
import { CROPPER_HANDLE, ACTION_NONE } from '@cropper/utils';

var style = `:host{background-color:var(--theme-color);display:block}:host([action=move]),:host([action=select]){height:100%;left:0;position:absolute;top:0;width:100%}:host([action=move]){cursor:move}:host([action=select]){cursor:crosshair}:host([action$=-resize]){background-color:transparent;height:15px;position:absolute;width:15px}:host([action$=-resize]):after{background-color:var(--theme-color);content:"";display:block;height:5px;left:50%;position:absolute;top:50%;transform:translate(-50%,-50%);width:5px}:host([action=n-resize]),:host([action=s-resize]){cursor:ns-resize;left:50%;transform:translateX(-50%);width:100%}:host([action=n-resize]){top:-8px}:host([action=s-resize]){bottom:-8px}:host([action=e-resize]),:host([action=w-resize]){cursor:ew-resize;height:100%;top:50%;transform:translateY(-50%)}:host([action=e-resize]){right:-8px}:host([action=w-resize]){left:-8px}:host([action=ne-resize]){cursor:nesw-resize;right:-8px;top:-8px}:host([action=nw-resize]){cursor:nwse-resize;left:-8px;top:-8px}:host([action=se-resize]){bottom:-8px;cursor:nwse-resize;right:-8px}:host([action=se-resize]):after{height:15px;width:15px}@media (pointer:coarse){:host([action=se-resize]):after{height:10px;width:10px}}@media (pointer:fine){:host([action=se-resize]):after{height:5px;width:5px}}:host([action=sw-resize]){bottom:-8px;cursor:nesw-resize;left:-8px}:host([plain]){background-color:transparent}`;

class CropperHandle extends CropperElement {
    constructor() {
        super(...arguments);
        this.$onCanvasCropEnd = null;
        this.$onCanvasCropStart = null;
        this.$style = style;
        this.action = ACTION_NONE;
        this.plain = false;
        this.slottable = false;
        this.themeColor = 'rgba(51, 153, 255, 0.5)';
    }
    static get observedAttributes() {
        return super.observedAttributes.concat([
            'action',
            'plain',
        ]);
    }
}
CropperHandle.$name = CROPPER_HANDLE;
CropperHandle.$version = '2.0.0-beta';

export { CropperHandle as default };
